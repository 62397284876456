body {
  overflow-x: hidden;
}

.navbar {
  border-bottom: dimgray 3px solid;
}

.card-tag {
  display: inline-block;
  font-size: 0.7em;
  padding: 1px 6px 2px 6px;
  border: 1px solid #5a5a5d !important;
  color: #5a5a5d !important;
  text-decoration: none;
}

a.card-title {
  color: black;
  text-decoration: none;
}

.post {
  margin-bottom: 1rem;
}

.post-text {
  font-size: 1.2rem;
}

.post-image {
  width: 100%;
  height: auto;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

.info {
  border-bottom: black 1px solid;
}

.main-img {
  margin-top: -16px;
  height: 460px;
  background-position: center;
  background-attachment: fixed;
}

.comment_date {
  margin-top: -10px;
}

.post_card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.about-img {
  width: 230px;
  height: 230px;
  object-fit: cover;
  margin: 0 auto;
  margin-top: 8px;
}

/* Default (Light mode) scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox scrollbar styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Dark mode scrollbar styles */
@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #2b2b2b;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 10px;
    border: 3px solid #2b2b2b;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }

  /* Firefox scrollbar styles for dark mode */
  * {
    scrollbar-width: thin;
    scrollbar-color: #444 #2b2b2b;
  }
}